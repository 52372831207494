import React from "react"

import "./footer.css"

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="main-footer__container">
        <p>© 2020 PayMe</p>
        <p><span>Termos de uso e serviço</span></p>
        <p className="main-footer__social">
          <span>Facebook</span>
          <span>Instagram</span>
        </p>
      </div>
    </footer>
  )
}

export default Footer
