import { Link } from "gatsby"
import React from "react"

import "./header.css"

const Header = () => {
  return (
    <header className="main-header">
      <div className="main-header__container">
        <h1>
          <Link to="/">Payme</Link>
        </h1>

        <div className="main-header__nav">
          <Link to="/faq" className="link">Perguntas Frequentes</Link>

          <a href="https://paymeapp.onrender.com/" className="buttonWhite">Login com Tratto</a>
        </div>
      </div>
    </header>
  )
}

export default Header
